.ui.header.mainHeading{
    color: gray;
    margin-top:0px
}
.ui.container.appHeader{
    border-top-color:#13bfb3 !important ;
    border-top-width: 10px;
    border-top-style: solid;
}
.header.item.ayuslogo{
    padding-bottom: 0px !important;
}