.padding{
    padding-top: 50px;
}

.dash-board{
    //padding-top: 100px;
    padding-bottom: 50px;
}
.ui.header.welcome-header{
    padding: 20px 10px;
}